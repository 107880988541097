.root
    display: flex
    flex-direction: column
    justify-content: center
    gap: 65px
    height: 100%
    .title
        font-size: 35px
        @media screen and (max-width: 500px)
            font-size: 36px
    .form
        display: flex
        flex-direction: column
        gap: 2rem
        .submitButton
            font-size: 18px
            font-weight: 700
            line-height: 20px
            text-transform: uppercase
            padding: 20px 50px !important
            width: 70%
            margin: auto
.card
    background: #F5F5F5
    border-radius: 30px
    padding: 32px
    position: relative
    @media screen and (max-width: 1200px)
        padding: 1rem
    .name
        font-size: 18px
        color: #0D0D0D
    .avatar
        height: 6.25rem
        width: 6.25rem
        font-size: 1.75rem
        background: #4ecc0a
        text-transform: uppercase
        cursor: pointer
        &:hover
            filter: brightness(1.2)
    .editBtn
        position: absolute
        right: 16px
        top: 16px

.button
    display: flex
    justify-content: center
    .submitButton
        border-radius: 36px
        font-size: 14px
        background-color: #000
        // padding: 1rem 5rem
        // width: fit-content
        width: 100%
        text-transform: none
        &:hover
            background: #5a5a5a
        &:disabled
            background: #F6F8FE
        @media screen and (max-width: 1200px)
            width: 100%
    .secondaryButton
        border-radius: 36px
        font-size: 14px
        background-color: #F6F8FE
        color: #000
        // padding: 1rem 5rem
        // width: fit-content
        width: 100%
        text-transform: none
        box-shadow: none
        &:hover
            background: #eeeeee
        &:disabled
            background: #F6F8FE
        @media screen and (max-width: 1200px)
            width: 100%

.inputAvatar
    display: none

.cameraLabel
    position: absolute
    z-index: 99
    cursor: pointer
    display: flex
    align-items: center
    justify-content: center
    right: 2%
    bottom: 2%
    border-radius: 50%
    background: #838383
    padding: 5px
    color: #fff
    opacity: 0.4
    &:hover
        opacity: 1
        background: #3e3e3e

.slot
    color: #4ecc0a
    border: 1px solid #4ecc0a
    border-radius: 30px
    padding: 4px 8px
    display: flex
    align-items: center

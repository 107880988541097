.root
    display: flex
    flex-direction: column
    flex: 50%
    // gap: 2.5rem
    background: #FFF
    border-radius: 30px
    padding: 3rem
    box-shadow: 0px 30px 60px rgb(57 57 57 / 10%)
    @media screen and (max-width: 1200px)
        box-shadow: none
        padding: 3rem 2rem
    .title
        font-size: 26px
        font-weight: 600
        height: 67px
    .button
        display: flex
        justify-content: center
        margin-top: 2.5rem
        .submitButton
            font-size: 15px
            padding: 1rem 2rem
            width: 60%
            text-transform: none
            @media screen and (max-width: 1200px)
                width: 100%
    input
        font-size: 1.2rem

// .carousel .slider-wrapper {
//   width: calc(100% - 1rem) !important;
//   margin-left: 1rem !important;
// }

.slide {
  pointer-events: none;
  & > div {
    height: 100%;
  }
}
.slide.selected {
  pointer-events: all;
}

.slider-wrapper {
  .slider {
    & > li {
      padding: 1rem;
      @media screen and (max-width: 1200px) {
        padding-left: 0;
      }
    }

    & > li:first-of-type {
      padding-left: 0;
    }
    & > li:last-child {
      padding-right: 0;
      .slide-item {
        @media screen and (min-width: 1200px) {
          & > div {
            margin: 0;
          }
        }
      }
    }
  }
}

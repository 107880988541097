.root
    display: flex
    flex-direction: column
    border-radius: 30px
    padding: 1rem 2rem
    .title
        font-size: 26px
        font-weight: 600
        height: 67px
    .button
        display: flex
        justify-content: center
        margin-top: 2.5rem
        .submitButton
            border-radius: 36px
            font-size: 15px
            background-color: #000
            padding: 1rem 2rem
            width: 60%
            text-transform: none
            &:hover
                background: #5a5a5a
            &:disabled
                background: #F6F8FE
            @media screen and (max-width: 1200px)
                width: 100%
    input
        font-size: 1.2rem

.body
    & > form
        padding: 1rem 2rem !important
        margin-top: 0 !important

.root
    .wrapper
        // background: #FFFFFF
        // box-shadow: 0px 2px 10px 0px rgba(19, 17, 32, 0.15)
        // border-radius: 30px
        // padding: 1rem 3rem
        @media screen and (max-width: 1200px)
            z-index: 2
            margin-top: -1rem
            padding: 3rem 1rem 4rem
            border-radius: 30px 30px 0 0
            box-shadow: none
    .headerMobile
        display: none
        @media screen and (max-width: 1200px)
            display: flex
            align-items: center
            gap: 0.5rem
            margin-bottom: 24px
        .title
            margin: 0
            font-weight: 500
    .header
        display: flex
        align-items: center
        gap: 1.5rem
        @media screen and (max-width: 1200px)
            display: none
        .title
            margin: 0
            font-weight: bold
    .content, .statisticContent
        // background: #FFFFFF
        // box-shadow: 0px 2px 10px 0px rgba(19, 17, 32, 0.15)
        // border-radius: 30px
        // padding: 1rem 3rem
    .statisticContent
        // padding: 3rem 5rem 4rem
        @media screen and (max-width: 1200px)
            z-index: 2
            margin-top: 0
            // padding: 3rem 1rem 4rem
            border-radius: 30px 30px 0 0
            box-shadow: none
        .desc
            font-size: 17px
            line-height: 36px
            hyphens: auto
        .button
            // background: #4ecc0a
            color: #FFF
            // border-radius: 30px
            padding: 14px 24px
            margin-bottom: 3rem
            width: 100%
            font-size: 14px
            display: none
            text-transform: none
            &:hover
                background: #b976ff
            @media screen and (max-width: 1200px)
                display: block
    .statistic
        display: flex
        @media screen and (max-width: 1200px)
            display: none
    .statisticMobile
        display: none
        margin: 1rem 0
        @media screen and (max-width: 1200px)
            display: flex
            justify-content: center
            gap: 16px
    .statistic, .statisticMobile
        .content
            display: flex
            width: calc(100% - 6rem)
            height: calc(100% - 2rem)
            flex-wrap: nowrap
            gap: 1.2rem
            justify-content: flex-start
            align-items: center
            cursor: pointer
            position: relative
            @media screen and (max-width: 1200px)
                justify-content: center
            &:not(.disabled)
                &:hover
                    background: #4ecc0a
                    color: #FFF
                    .icon
                        color: #FFF
                        & *
                            fill: #AE81E0
            .icon
                width: 30px
                height: 30px
                color: #AE81E0
                & *
                    fill: #AE81E0
            .name
                font-size: 17px
.photos
    margin-top: 2rem

.content.disabled
    background: #d9d9d9 !important
    &:hover
        background: #d9d9d9 !important

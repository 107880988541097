.formGroup
    display: flex
    justify-content: space-between
    gap: 2.5rem
    margin-top: 2.5rem
    @media screen and (max-width: 1200px)
        flex-direction: column
        gap: 0
        margin: 0
        & > form:first-of-type
            border-radius: 36px 36px 0 0
        & > form:not(:first-of-type)
            border-radius: 0
.profileTop
    position: relative
    .editBtn
        position: absolute
        top: 50px
        right: 0
        border: 1px solid
        border-radius: 4px
        z-index: 9
        @media screen and (max-width: 1200px)
            top: 1.5rem
            right: 1.5rem

.addCompany
    position: absolute !important
    top: 0
    right: 0
    @media screen and (max-width: 1200px)
        position: relative !important

.MuiPaper-root.MuiPopover-paper {
  max-height: 50vh;
}

.MuiFormLabel-root.Mui-required {
  display: flex;
  // flex-direction: row-reverse;
  gap: 4px;
  width: fit-content;
}

// ====== react-toastify
.Toastify__toast-body {
  width: 100%;
  & > * {
    width: 100%;
  }
}

//====
.fc-list-event-title {
  white-space: break-spaces;
}

.fc-more-popover {
  visibility: hidden;
}

.formAddNew
    padding: 2rem
    margin: auto
    width: 50%
    display: flex
    flex-direction: column
    gap: 16px
    @media screen and (max-width: 1200px)
        width: calc(100% - 4rem)
    .submitButton
        font-size: 15px
        padding: 1rem 5rem
        width: fit-content
        text-transform: none
        margin: auto
.content
    .header
        display: flex
        align-items: center
        gap: 1.5rem
        .title
            margin: 0
            font-weight: 500
    .userSection
        margin: 3rem 0
        @media screen and (max-width: 1200px)
            margin-bottom: 0
    .status
        background: #2AB740
        color: #FFF
        width: fit-content
        border-radius: 30px
        padding: 6px 18px
        margin-bottom: 6px
        font-size: 12px
        cursor: auto
        @media screen and (max-width: 1200px)
            margin-right: 0 !important
    .desc
        font-size: 17px
        line-height: 36px
    .button
        padding: 14px 24px
        margin-bottom: 3rem
        width: 100%
        font-size: 14px
        display: none
        @media screen and (max-width: 1200px)
            display: block

.editBtn
    border: 1px solid !important
    border-radius: 4px !important
    margin-top: -30px

.name
    font-size: 20px
    font-weight: 600
    text-transform: capitalize
    overflow-wrap: anywhere
    color: inherit
.email
    font-size: 16px
    color: inherit

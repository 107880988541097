@use '../../main-layout.module.sass' as layout

.header
    min-height: 120px
    border-bottom: 1px solid layout.$border-color

    .logo
        @include layout.left-content
        display: flex
        align-items: center
        padding: 24px layout.$padding-horizontal
        img
            cursor: pointer

    .navigation
        @include layout.right-content
        padding: 24px layout.$padding-horizontal

@media screen and (max-width: 1200px)
    .header
        min-height: 64px
        .logo 
            padding: 8px 8px 8px layout.$padding-horizontal-mobile
            width: auto
            min-width: auto
            max-width: auto
        .navigation
            padding: 8px layout.$padding-horizontal-mobile 8px 8px
            .navigationDesktop
                display: none
.root
    display: flex
    flex-direction: column
    gap: 3.5rem
    margin-bottom: 2rem
    // width: calc(100% + 1rem)
    // margin: 0 -1rem 2rem
    @media screen and (max-width: 1200px)
        margin-bottom: 1rem
        gap: 3.5rem
        padding-left: 1rem
    .controlPrev, .controlNext
        position: absolute
        z-index: 999
        top: 50%
        transform: translateY(-50%)
        font-size: 26px
        color: #fff
        background: #000
        &:hover
            background: #5a5a5a
        @media screen and (max-width: 414px)
            display: none
    .controlPrev
        left: 0
        @media screen and (max-width: 1200px)
            left: 1rem
    .controlNext
        right: 0
        @media screen and (max-width: 1200px)
            right: 1rem

.root
    // margin-top: 2.5rem !important
    @media screen and (max-width: 1200px)
        position: absolute
        top: 0
        left: 0
        right: 0
        z-index: 999
    .breadcrumbs
        display: flex
        justify-content: space-between
        align-items: center
        @media screen and (max-width: 1200px)
            display: none
    .headerMobile
        display: none
        justify-content: space-between
        padding: 0 1.5rem
        @media screen and (max-width: 1200px)
            display: flex
        .avatar
            font-size: 1rem
            background: #4ecc0a
            text-transform: uppercase

@import "./font.sass"

html
  font-size: 16px
  @media screen and (max-width: 1536px)
    font-size: 14px

body
  padding: 0
  margin: 0

  p
    margin: 0

body ::-webkit-scrollbar
  -webkit-appearance: none
body
  @media screen and (max-width: 1200px)
    ::-webkit-scrollbar
      width: 0px

body *
  // font-family: 'Mulish', Arial, sans-serif
  font-family: 'SF Pro Display', 'Lato', Arial, sans-serif !important

.cookies-banner
  position: fixed !important
  bottom: 0 !important
  background-color: rgba(72 , 72, 72, 0.8) !important
  height: auto !important
  padding: 12px 24px
  display: flex
  justify-content: center
  align-items: center
  gap: 16px
  *
    line-height: 1.2 !important
    font-size: 16px !important
  button
    position: relative !important
    inset: 0 !important
    height: 28px !important
    margin: 0 !important

.spinner-border
  display: flex
  justify-content: center
  align-items: center
  border: none

.fade-in-enter
  opacity: 0

.fade-in-enter-active
  opacity: 1
  transition: all 0.5s

.fade-in-exit
  opacity: 1

.fade-in-exit-active
  opacity: 0
  transition: all 0.5s

// div.react-datepicker
//   border-radius: 0
//   border: 1px solid #9AA3E1
//   // &__triangle
//   //   &::before,&::after
//   //     left: -123px!important
//   ::-webkit-scrollbar
//     width: 1px
//   & li
//     display: flex
//     align-items: center
//     justify-content: center
//   &__month
//     margin: 0
//   &__day
//     margin: 0
//     border-radius: 0
//     width: 35px
//     line-height: 35px
//     &:hover
//       border-radius: 0
//     &--disabled
//       cursor: not-allowed
//     &-name
//       margin: 0
//       width: 35px
//       line-height: 35px

.MuiPhoneNumber-flagButton
  width: 30px

.limit-row
  display: -webkit-box
  -webkit-box-orient: vertical
  -webkit-line-clamp: 3
  text-overflow: ellipsis
  overflow: hidden

.overflow-wrap-any
  overflow-wrap: anywhere

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item
    margin: 0 !important

.react-datepicker__time-list-item.react-datepicker__time-list-item--disabled
  display: none !important

.filters
  padding-top: 14px
  .input
    input
      border-radius: 4px
      border: 1px solid black
      display: inline-block
      width: 100%
  .select
  .button
    width: 100%
    padding: 6px 16px
    margin-bottom: 8px

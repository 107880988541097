.atom-icon-button
    padding: 12px
    height: 48px
    width: 48px
    border: none
    outline: none
    color: #161616
    background-color: #EEEEEE
    cursor: pointer
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1), border-color 250ms cubic-bezier(0.4, 0, 0.2, 1), color 250ms cubic-bezier(0.4, 0, 0.2, 1)
    &:hover
        background-color: darken(#EEEEEE, 15%)
    &.primary
        background-color: #4ecc0a
        &:hover
            background-color: darken(#4ecc0a, 15%)
    &.selected
        background-color: #161616
        color: #FFF
        &:hover
            background-color: darken(#161616, 15%)
    &.orange
        background-color: #fff1dc
        &:hover
            background-color: darken(#fff1dc, 5%)
    &.blue
        background-color: #d8ecff
        &:hover
            background-color: darken(#d8ecff, 5%)
.root
    @media screen and (max-width: 1200px)
        margin: 0 1rem
.title
    font-weight: 500
    margin: 2rem 0
    @media screen and (max-width: 1200px)
        margin-top: 1rem
.formGroup
    display: flex
    justify-content: space-between
    gap: 2.5rem
    margin-top: 2.5rem
    @media screen and (max-width: 1200px)
        flex-direction: column
        gap: 0
        margin: 0
        & > form:first-of-type
            border-radius: 36px 36px 0 0
        & > form:not(:first-of-type)
            border-radius: 0
.content
    background: #FFFFFF
    // box-shadow: 0px 2px 10px 0px rgba(19, 17, 32, 0.15)
    // border-radius: 30px
    padding: 3rem 2rem

.project, .projectMobile
    margin-bottom: 40px
    .image
        margin: 0 22px 0 10px
        border-radius: 30px
        border: 1px solid rgb(136 136 136 / 10%)
        box-shadow: 0px 2px 10px 0px rgba(19, 17, 32, 0.15)
        object-fit: cover
    .name
        font-weight: 600
        font-size: 24px
        line-height: 32px
        letter-spacing: 0.02em
        margin: 4px 0
        display: -webkit-box
        -webkit-line-clamp: 3
        -webkit-box-orient: vertical
        overflow: hidden
    .count
        font-weight: 400
        font-size: 20px
        line-height: 28px
    .date
        font-weight: 400
        font-size: 17px
        line-height: 20px
        color: #8A8A8E
    .price
        font-weight: 600
        font-size: 24px
        line-height: 34px
        letter-spacing: 0.01em
        width: max-content
        white-space: nowrap
    .desc
        margin-top: 18px
        font-weight: 400
        font-size: 17px
        line-height: 28px
        color: #ADADAF
        display: -webkit-box
        -webkit-line-clamp: 4
        -webkit-box-orient: vertical
        overflow: hidden
        position: relative
        &::before
            content: ""
            position: absolute
            bottom: 0
            width: 100%
            height: 50%
            background: linear-gradient(349.22deg, #FFFFFF 9.03%, rgba(255, 255, 255, 0) 93.07%)

.projectMobile
    display: none !important

@media screen and (max-width: 1200px)
    .projectMobile
        display: flex !important
        .image
            margin: 0
    .project
        display: none !important

.showMore
    width: fit-content
    border: 1px solid #4ecc0a
    color: #4ecc0a
    padding: 8px 16px
    cursor: pointer
    margin: 14px auto 0
    &:hover
        background: #4ecc0a
        color: #FFF

.programItem
    padding: 1.5rem 1rem
    &:hover
        background: #f9f9f9
        border-radius: 10px

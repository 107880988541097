.root
    min-height: calc(100vh - 80px)
    .buttonShow
        border-radius: 30px !important
        background-color: #4ecc0a !important
        color: #FFF
        padding: 12px 24px !important
        width: fit-content
        height: fit-content
        min-width: 150px
        white-space: nowrap
        &:hover
            background-color: #b976ff !important
        &:disabled
            background: #F6F8FE !important
    .buttonReset
        color: #8A8A8E
        padding: 12px 24px !important
        width: fit-content
        height: fit-content
        min-width: 150px
        &:hover
            background: transparent !important
    .buttonControl
        padding: 0px 0px
        display: flex
        flex-direction: column
        @media screen and (max-width: 1200px)
            display: flex
            flex-direction: row
            margin-bottom: 12px

.filter
    @media screen and (max-width: 1200px)
        flex-direction: column !important

.company
    background: #FFFFFF !important
    box-shadow: 0px 2px 10px 0px rgba(19, 17, 32, 0.15) !important
    border-radius: 30px !important
    padding: 32px !important
    position: relative
    height: 100%
    @media screen and (max-width: 1200px)
        padding: 2rem 1rem !important
    .logo
        max-height: 80px
        max-width: 300px
        height: 100%
        object-fit: cover
        padding-right: 15px
        @media screen and (max-width: 1200px)
            max-height: 100px
    .expandBtn
        cursor: pointer
        height: 70px
        background: linear-gradient(180deg, #FFFFFF 9.03%, rgba(245, 245, 245, 0) 93.07%)
        border-radius: 0 0 30px 30px
        position: absolute
        z-index: 1
        bottom: 0
        right: 32px
        left: 32px
        display: flex
        justify-content: center
        align-items: center
    .desc
        display: -webkit-box
        // -webkit-line-clamp: 3
        -webkit-box-orient: vertical
        overflow: hidden
        overflow-wrap: break-word

.avatar
    // float: left
    width: 85px
    height: 85px
    // margin-right: 2rem
    font-size: 28px

.vizitWrapper
    flex-direction: row !important
    flex-wrap: wrap
    & > div
        width: calc(50% - 76px)
        @media screen and (max-width: 1200px)
            width: 100% !important

.input
    margin-top: 0 !important
    & > div
        border-radius: 10px
        font-size: 17px

.root
    min-height: 100dvh
    // .container
    //     @media screen and (max-width: 1200px)
    //         flex-direction: column-reverse
    // .left
    //     height: 100vh
    //     width: 100%
    //     padding: 60px !important
    //     background-size: cover
    //     background-repeat: no-repeat
    //     overflow: auto
    //     @media screen and (max-width: 1200px)
    //         padding: 0px 30px 60px !important
    //         height: auto
    //         position: absolute
    //         top: 27vh
    //         background: #FFF !important
    //         border-radius: 30px 30px 0 0
    // .right
    //     height: 100vh
    //     width: 100vh
    //     background-repeat: no-repeat
    //     background-size: cover
    //     @media screen and (max-width: 1200px)
    //         height: 30vh
    //         width: 100vw
    //         background-size: 100vw 30vh
    //     .image
    //         max-height: 60%
    //         max-width: 60%
    //     .language
    //         position: absolute
    //         top: 1rem
    //         right: 1rem
    //         display: none
    //         @media screen and (max-width: 1200px)
    //             display: block

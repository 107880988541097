$primary-color: #4ecc0a

.content
    .header
        display: flex
        align-items: center
        gap: 8px
        margin-bottom: 24px
        .title
            margin: 0
            font-weight: bold
.optionList
    margin-top: 2rem
    .option
        display: flex
        flex-direction: column
        gap: 16px
        padding: 3rem
        background: #eaeaea
        border-radius: 30px
        height: 100%
        position: relative
        .service
            position: relative
        .service:before
            content: "\2022"
            color: grey
            position: absolute
            left: -12px
        .title
            font-size: 20px
            text-align: center
            color: $primary-color
        .button
            width: 100%
            border-radius: 30px
            padding: 10px 16px
            font-size: 15px
            box-shadow: none
            text-transform: none
            position: absolute
            bottom: -20px
            left: 0
            right: 0
            width: 80%
            margin: 0 auto

.root
    padding: 1rem 3rem
    width: 100%
    cursor: pointer
    position: relative
    &:hover
        background: #f7f7f7
    @media screen and (max-width: 1200px)
        padding: 0 1rem
    .name
        font-size: 1.25rem
        font-weight: 600
        text-overflow: ellipsis
        overflow: hidden
        overflow-wrap: break-word
    .logo
        width: 100%
        border-radius: 8px
    .dateRange
        height: 36px
        font-weight: 400
        color: #171717
        text-transform: capitalize
        @media screen and (max-width: 1200px)
            height: auto
    .description
        font-size: 1.2rem
        font-weight: 400
        color: #ADADAF
        margin-top: 8px
        @media screen and (max-width: 1200px)
            display: -webkit-box
            -webkit-line-clamp: 5
            -webkit-box-orient: vertical
            overflow: hidden
            position: relative
            &::after
                content: ""
                background: linear-gradient(349.22deg, #FFFFFF 9.03%, rgba(255, 255, 255, 0) 93.07%)
                position: absolute
                left: 0
                right: 0
                bottom: 0
                height: 30%
    .joinProjectButton
        position: absolute
        left: 2rem
        top: 1rem
    .joinProjectButtonInline
        width: fit-content
        padding-bottom: 1rem

.root
    display: flex
    align-items: center
    gap: 10px
    .avatar
        height: 3rem
        width: 3rem
        min-height: 3rem
        min-width: 3rem
        text-transform: uppercase
    .avatarBig
        height: 4.5rem
        width: 4.5rem
        min-height: 4.5rem
        min-width: 4.5rem

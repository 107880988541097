.sidebar
    &__tab
        padding: 16px
        cursor: pointer
        display: flex
        align-items: center
        gap: 16px
        &-title
            font-size: 16px
            font-weight: 400
            line-height: 27px
            text-align: left
            color: #161616
        &-icon
            display: flex
            height: fit-content
            width: fit-content
            color: #4ecc0a

        &:hover
            background-color: #EEEEEE
        &.selected
            background-color: #4ecc0a
            .sidebar__tab-icon
                color: #F9F9FA
            .sidebar__tab-title
                color: #FFF
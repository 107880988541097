.root
  display: flex
  border: 1px solid #9AA3E1
  border-radius: 2px
  height: 28px
  width: fit-content
  margin: 0 auto
  box-sizing: content-box
  .plus, .minus
    background-color: #E2E2EA
    height: inherit
    aspect-ratio: 1/1
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer
    user-select: none

  .amount
    min-width: 42px
    max-width: 62px
    display: flex
    justify-content: center
    align-items: center
    text-align: center
    border: none
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button
      -webkit-appearance: none
      margin: 0

.root
  .input
    width: 100%
    div
      border-radius: 10px
    input
      border-radius: 4px
      // border: 1px solid black
      width: 100%
      // margin: 15px 0 25px 0 !important
  .errorWrapper
    position: relative
    .error
      position: absolute
      bottom: -12px
      font-size: 15px
      line-height: 1
      left: 0
      color: red

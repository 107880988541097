.root
    display: flex
    flex-direction: column
    gap: 2.5rem
    overflow: auto
    -ms-overflow-style: none
    scrollbar-width: none
    margin-bottom: 3rem
.showMore
    display: none
    margin-top: 3rem
    @media screen and (max-width: 1200px)
        display: flex
        justify-content: center

.root
    display: flex
    align-items: center
    gap: 1.75rem
    cursor: pointer
    @media screen and (max-width: 1200px)
        flex-direction: column
    .avatar
        height: 6.25rem
        width: 6.25rem
        font-size: 1.75rem
        background: #4ecc0a
        text-transform: uppercase
    .info
        .name
            font-weight: 600
            font-size: 1.15rem
            text-transform: capitalize
        .email
            font-weight: 400
            line-height: 18px
            color: #4ecc0a
.currentUser
    .avatar
        background: #9d9d9d
    .info
        .name
            color: #000
        .email
            color: #4ecc0a

.root
    display: flex
    flex-direction: column
    align-items: center
    gap: 8px
    flex-wrap: nowrap
    position: relative
    margin: 1rem auto
    overflow: scroll
    -ms-overflow-style: none
    scrollbar-width: none
    .title
        width: calc(100% - 1rem)
        text-transform: capitalize
        margin: 0 0 1rem
    .calendar
        display: grid
        grid-template-columns: repeat(7, auto)
        grid-gap: 0.5rem
        width: 100%
        .baseDate
            margin-bottom: 0.5rem
            text-transform: capitalize
            .numDate
                color: #3C3C4399 !important
        .dateCell, .baseDate
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            cursor: pointer
            font-size: 1.15rem
            .numDate
                display: flex
                flex-direction: column
                justify-content: center
                align-items: center
                width: 2rem
                height: 2rem
                padding: 0.5rem
                color: #333333
            .numDate.selected
                background: #4ecc0a
                border-radius: 50%
                color: #FFF !important

.root
    display: flex
    flex-direction: column
    flex: 50%
    // gap: 2.5rem
    background: #FFF
    border-radius: 30px
    padding: 3rem
    box-shadow: 0px 30px 60px rgb(57 57 57 / 10%)
    position: relative
    @media screen and (max-width: 1200px)
        box-shadow: none
        padding: 1rem 2rem 3rem
    .loadingCenter
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        height: 100%
        width: 70%
        margin: 0 auto
        @media screen and (max-width: 1200px)
            margin-top: 4rem
            margin-bottom: 2rem
    .top
        display: flex
        flex-direction: column
        // justify-content: space-between
        // align-items: center
        height: 67px
        position: relative
        @media screen and (max-width: 1200px)
            height: auto
    .title
        font-size: 26px
        font-weight: 600
    .link
        cursor: pointer
        text-decoration: underline
        color: #4ecc0a
    .button
        display: flex
        justify-content: center
        margin-top: 2.5rem
        .submitButton
            text-transform: none
            border-radius: 36px
            background-color: #000
            padding: 1rem 2rem
            width: 60%
            font-size: 15px
            &:hover
                background: #5a5a5a
            &:disabled
                background: #F6F8FE
            @media screen and (max-width: 1200px)
                width: 100%
    input
        font-size: 1.2rem
    select
        font-size: 1.2rem
    .uploadZone
        background: #F5F5F5
        border: 1px dashed #D9D9D9
        border-radius: 30px
        width: 100%
        height: 25rem
        display: flex
        justify-content: center
        align-items: center
        flex-direction: column
        cursor: pointer
        margin-top: 1.5rem
        &:hover
            border: 1px dashed #000000
    .file
        display: flex
        align-items: center
        gap: 1rem
        @media screen and (max-width: 1200px)
            flex-direction: column
            align-items: flex-start
            gap: 0
        .fileInfo
            display: flex
            align-items: center
            gap: 0.5rem
        .icon
            color: #4ecc0a
            width: 1.5rem !important
            height: 1.5rem !important
        .name
            cursor: pointer
            &:hover
                text-decoration: underline

.primaryText
    font-size: 17px
    line-height: 24px
    display: flex
    align-items: center
    text-align: center
    color: #262626
.secondaryText
    font-weight: 400
    font-size: 15px
    line-height: 22px
    display: flex
    align-items: center
    text-align: center
    color: #8A8A8E

.root
    position: relative
    .number
        font-size: 20px
        text-align: center
        margin: 0 22px 8px 10px
        padding-top: 8px
    .image
        border-radius: 30px
        border: 1px solid rgb(136 136 136 / 10%)
        box-shadow: 0px 2px 10px 0px rgba(19, 17, 32, 0.15)
        object-fit: scale-down
    .name
        font-weight: 600
        font-size: 24px
        line-height: 32px
        letter-spacing: 0.02em
        margin: 4px 0
        display: -webkit-box
        -webkit-line-clamp: 3
        -webkit-box-orient: vertical
        overflow: hidden
    .invoice
        font-weight: 400
        color: #FFF
        background: #4ecc0a
        width: fit-content
        padding: 8px 16px
        border-radius: 18px
    .orderNumber
        font-weight: 100
        color: #140202
        background: #ede7f2
        width: fit-content
        padding: 8px 14px
        border-radius: 18px
    .count
        font-weight: 400
        font-size: 20px
        line-height: 28px
    .date, .product, .viewPDF
        font-weight: 400
        font-size: 18px
        line-height: 20px
        color: #8A8A8E
    .viewPDF
        text-decoration: underline
        text-align: center
        cursor: pointer
        color: #000
        font-size: 15px
    .price
        font-weight: 600
        font-size: 24px
        line-height: 34px
        letter-spacing: 0.01em
        width: max-content
        white-space: nowrap
    .desc
        margin-top: 18px
        font-weight: 400
        font-size: 17px
        line-height: 28px
        color: #ADADAF
        display: -webkit-box
        -webkit-line-clamp: 4
        -webkit-box-orient: vertical
        overflow: hidden
        position: relative
        &::before
            content: ""
            position: absolute
            bottom: 0
            width: 100%
            height: 50%
            background: linear-gradient(349.22deg, #FFFFFF 9.03%, rgba(255, 255, 255, 0) 93.07%)
    .deleteIcon
        position: absolute
        top: 1rem
        right: 1rem

.root
    height: calc(100% - 8px)
    display: flex
    align-items: flex-end
    margin-bottom: 8px
    .inputAvatar
        display: none
    .text
        text-decoration: underline
        font-size: 17px
        color: #4ecc0a
        cursor: pointer
    .text.disabled
        pointer-events: none
        cursor: auto
        color: #00000061
    .file
        display: flex
        align-items: center
        gap: 1rem
        @media screen and (max-width: 1200px)
            flex-direction: column
            align-items: flex-start
            gap: 0
        .fileInfo
            display: flex
            align-items: center
            gap: 0.5rem
        .icon
            color: #4ecc0a
            width: 1.5rem !important
            height: 1.5rem !important
        .name
            cursor: pointer
            &:hover
                text-decoration: underline
